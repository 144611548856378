<template>
  <b-card>
    <!-- Title -->
    <b-card-title class="text-primary">
      Store Configuration
    </b-card-title>

    <b-form @submit.prevent="formSubmitHandler">
      <!-- Show MRP -->
      <b-form-group>
        <b-form-checkbox
          v-model="form.mrpHidden"
          id="mrpHidden"
          name="mrpHidden"
        >
          Hide MRP
        </b-form-checkbox>
      </b-form-group>

      <!-- Auto Whatsapp Order -->
      <b-form-group>
        <b-form-checkbox
          v-model="form.autoWhatsappOrder"
          id="autoWhatsappOrder"
          name="autoWhatsappOrder"
        >
          Auto Whatsapp order
        </b-form-checkbox>
      </b-form-group>

      <b-button type="submit" variant="primary"> Submit </b-button>
    </b-form>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    // creating form from store
    const { mrpHidden, autoWhatsappOrder } = this.store;
    this.form = { mrpHidden, autoWhatsappOrder };
  },
  data() {
    return {
      form: {
        mrpHidden: false,
        autoWhatsappOrder: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      store: "merchant/store/getStore",
    }),
  },
  methods: {
    ...mapActions({
      updateStoreConfig: "merchant/store/updateStoreConfig",
    }),
    formSubmitHandler() {
      this.updateStoreConfig(this.form).then(() => this.$router.go(-1));
    },
  },
};
</script>

<style></style>
